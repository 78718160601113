import React from 'react'

import {
  Typography,
  Grid,
  Button,
  ButtonGroup,
  IconButton,
  Popover,
  List,
  Tooltip,
} from '@material-ui/core'
import theme from '../../../theme'
import { ValidatorForm } from 'react-material-ui-form-validator'

import { TelemetryStatus, StatusRange, TelemetryType } from 'api/alertservice'
import {
  celsiusToFahrenheit,
  getReadableTelemetryStatus,
} from 'components/utils/converter'
import { getStatusLensColor } from '../../utils'
import { AddCircle, RotateLeftOutlined } from '@material-ui/icons'
import { Range } from 'rc-slider'
import 'rc-slider/assets/index.css'
import ThresholdRange from './ThresholdRange'
import {
  formatActualTankLevelFeet,
  formatTelemetryTypeAndUnit,
} from 'components/utils/formatter'
import { TitleAndSubtitle } from 'components/misc/TitleAndSubtitle'
import * as LDClient from 'launchdarkly-js-client-sdk'
import { Site } from '../../../store/Site'

export interface TelemetryThresholdsComponentState {
  readonly allowSave?: boolean
  readonly type: TelemetryType
  readonly statusRanges: StatusRange[]
}

export interface Props {
  readonly type: TelemetryType
  readonly siteId: string
  readonly site: Site | undefined
  readonly assetId: string
  readonly thresholds: StatusRange[]
  readonly stateUpdated: (state: TelemetryThresholdsComponentState) => void
  readonly resetAssetThresholds?: () => void
}

interface UserProps {
  readonly isAdmin: boolean
  readonly tenantId: number
  readonly launchDarklyClientsideId: string
}

interface ThresholdsMarkPossition {
  readonly paddingLeft: number | null
  readonly paddingRight: number | null
  readonly marginTop: number | null
}

interface State {
  readonly statusRanges: StatusRange[]
  readonly isAddMenuOpened: boolean
  readonly tickMarks: number[]
  readonly editedStatusRangeIndex?: number
  readonly editedStatusRange?: StatusRange
  readonly saveEnabled?: boolean
  readonly showResetConfirmation?: boolean
  readonly featureFlags: any
  readonly showThresholdPumpControl: boolean
  readonly threshold: any
  readonly selectedPumps: any
}

type AllProps = Props & UserProps

const supportedTelemetryStatuses = Object.values(TelemetryStatus).filter(
  (status) =>
    typeof status !== 'number' &&
    status !== TelemetryStatus[TelemetryStatus.Undefined]
)

const maximumThresholdsPerStatusAllowed = 3
const maximumFlareStatusThresholdsPerStatusAllowed = 1
const maximumPumpControlStatusThresholdsPerStatusAllowed = 1

export class TelemetryThresholds extends React.Component<AllProps, State> {
  private readonly form = React.createRef<ValidatorForm>()
  private readonly addMenuAnchor = React.createRef<HTMLButtonElement>()

  constructor(props: AllProps) {
    super(props)

    const thresholds =
      props.thresholds
        ?.filter((th) =>
          supportedTelemetryStatuses.includes(TelemetryStatus[th.status])
        )
        .sort((th1, th2) => th1.minThreshold - th2.minThreshold) ?? []
    const tickMarks = thresholds.length > 0 ? this.getTicks(thresholds) : []

    this.state = {
      statusRanges: thresholds,
      tickMarks,
      isAddMenuOpened: false,
      featureFlags: null,
      showThresholdPumpControl: false,
      threshold: {
        status: 1,
        minThreshold: 0,
        maxThreshold: 132,
        isHighPriority: false,
        allowPumpControl: false,
      },
      selectedPumps: null,
    }

    this.handlePumpControlChange = this.handlePumpControlChange.bind(this)
    this.handleThresholdChange = this.handleThresholdChange.bind(this)
    this.saveThreshold = this.saveThreshold.bind(this)
    this.handleEditButtonClicked = this.handleEditButtonClicked.bind(this)
    this.handleRemoveButtonClick = this.handleRemoveButtonClick.bind(this)
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this)
    this.checkIntersectingRule = this.checkIntersectingRule.bind(this)
  }

  private featureFlag() {
    const user: LDClient.LDUser = {
      key: this.props.tenantId.toString(),
    }

    const LaunchDarklyClientsideId = this.props.launchDarklyClientsideId
    const options: LDClient.LDOptions = { bootstrap: 'localStorage' }
    const client = LDClient.initialize(LaunchDarklyClientsideId, user, options)

    client
      .waitForInitialization()
      .then(() => {
        const flags = client.allFlags()
        this.setState({
          featureFlags: flags,
          showThresholdPumpControl: flags && flags.ShowPump === true,
        })
      })
      .catch((err) => {
        console.error(
          '🚀 ~ file: AllSites.tsx ~ client.waitForInitialization ~ err',
          err
        )
      })
  }

  public componentDidMount() {
    this.featureFlag()
  }

  private getTicks(thresholds: StatusRange[]) {
    const lastIndex = thresholds.length - 1

    if (lastIndex < 0) {
      return []
    }

    const max = thresholds[lastIndex].maxThreshold
    const ticks = [...thresholds.map((t) => t.minThreshold), max]

    return ticks
  }

  private checkIntersectingRule(
    minThreshold: number,
    maxThreshold: number
  ): boolean {
    const {
      statusRanges: thresholds,
      editedStatusRangeIndex: editedThresholdIndex,
    } = this.state
    const allThresholdsExceptCurrent = thresholds.filter(
      (_, j) => editedThresholdIndex !== j
    )

    for (const element of allThresholdsExceptCurrent) {
      const min = element.minThreshold
      const max = element.maxThreshold

      // Max value within existing range  - { [ } ]
      if (maxThreshold < max && maxThreshold > min) {
        return false
      }

      // Min value within existing range - [ { ] }
      if (minThreshold < max && minThreshold > min) {
        return false
      }

      // The range withing another one - { [ ] }
      if (maxThreshold >= max && minThreshold <= min) {
        return false
      }

      // A range withing the range - [ { } ]
      if (maxThreshold <= max && minThreshold >= min) {
        return false
      }
    }

    return true
  }

  public render() {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '60px',
            flexWrap: 'wrap',
          }}
        >
          <TitleAndSubtitle
            title={formatTelemetryTypeAndUnit(this.props.type)}
            subtitle={`Asset: ${this.props.assetId}`}
          />
          <div
            style={{
              padding: theme.spacing(1),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <IconButton
              ref={this.addMenuAnchor}
              style={{
                marginLeft: 'auto',
                minWidth: 'auto',
              }}
              color="primary"
              size="small"
              disabled={this.isAddButtonDisabled}
              onClick={() =>
                this.setState({
                  isAddMenuOpened: true,
                })
              }
            >
              <AddCircle />
            </IconButton>
            <Tooltip title={`Reset`}>
              <IconButton
                onClick={() => this.onResetAssetThresholds()}
                size="small"
              >
                {<RotateLeftOutlined />}
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateRows: 'auto 1fr ',
            flex: '1 1',
          }}
        >
          {this.props.type !== TelemetryType.FlareStatus &&
            this.renderThresholdRanges()}
          {this.renderThresholds()}
        </div>
        {this.renderPopover()}
      </div>
    )
  }

  private onResetAssetThresholds() {
    if (this.state.statusRanges.length === 0) {
      return
    }

    this.setState({ statusRanges: [] })
    const statusRanges = []
    const { type } = this.props
    const allowSave = true
    this.props.stateUpdated({
      allowSave,
      statusRanges,
      type,
    })
  }

  private renderPopover() {
    const isFlare = this.props.type === TelemetryType.FlareStatus
    const isPump = this.props.type === TelemetryType.PumpControl

    let maxTh = !isFlare
      ? maximumThresholdsPerStatusAllowed
      : maximumFlareStatusThresholdsPerStatusAllowed

    maxTh = !isPump ? maxTh : maximumPumpControlStatusThresholdsPerStatusAllowed

    return (
      <Popover
        keepMounted={true}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        anchorEl={this.addMenuAnchor.current}
        open={this.state.isAddMenuOpened}
        onClose={() =>
          this.setState({
            isAddMenuOpened: false,
          })
        }
      >
        <ButtonGroup style={{ padding: theme.spacing(1) }}>
          {supportedTelemetryStatuses.map((s) => {
            const ts = TelemetryStatus[s]
            if (isFlare && ts === TelemetryStatus.Yellow) return
            if (isPump && ts === TelemetryStatus.Yellow) return
            const disabled =
              this.state.statusRanges.filter((th) => th.status === ts).length >=
              maxTh
            return (
              <Button
                key={s}
                style={
                  disabled
                    ? {}
                    : {
                        backgroundColor: getStatusLensColor(ts),
                        color: 'white',
                      }
                }
                onClick={() => this.handleAddButtonClick(ts)}
                disabled={disabled}
              >
                {getReadableTelemetryStatus(ts)}
              </Button>
            )
          })}
        </ButtonGroup>
      </Popover>
    )
  }

  private componentStateUpdated() {
    const allowSave = this.allowSave()
    const { statusRanges } = this.state
    const { type } = this.props

    this.props.stateUpdated({
      statusRanges,
      allowSave,
      type,
    })
  }

  private allowSave() {
    return (
      this.hasNoIntersectedThresholds() &&
      this.hasNoGapsBetweenThresholds() &&
      this.hasChanges() &&
      this.hasAtLeastOneGreenIfNotEmpty()
    )
  }

  private isFormValid(): Promise<boolean> {
    return this.form.current?.isFormValid(false) ?? Promise.resolve(false)
  }

  private hasChanges(): boolean {
    const thresholdsBeforeUpdate = JSON.stringify(this.props.thresholds)
    const thresholdsAfterUpdate = JSON.stringify(this.state.statusRanges)

    return thresholdsBeforeUpdate.localeCompare(thresholdsAfterUpdate) !== 0
  }

  private renderThresholds() {
    const { statusRanges, editedStatusRangeIndex } = this.state

    return (
      <div
        style={{
          paddingBottom: theme.spacing(1),
          paddingTop: theme.spacing(1),
        }}
      >
        {statusRanges.length > 0 &&
          this.props.type !== TelemetryType.FlareStatus &&
          this.props.type !== TelemetryType.PumpControl && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: 'grey',
                marginTop: theme.spacing(4),
              }}
            >
              <Typography
                style={{
                  width: '90px',
                  marginLeft: theme.spacing(5),
                }}
              >
                From
              </Typography>
              <Typography
                style={{
                  width: '90px',
                  marginLeft: theme.spacing(1),
                }}
              >
                To
              </Typography>
              {this.props.isAdmin &&
                this.props.type === TelemetryType.TankLevel && (
                  <Typography
                    style={{
                      width: '90px',
                      marginLeft: theme.spacing(1),
                    }}
                  >
                    High Priority
                  </Typography>
                )}
              {this.props.isAdmin &&
                this.state.showThresholdPumpControl &&
                this.props.type === TelemetryType.TankLevel && (
                  <Typography
                    style={{
                      width: '90px',
                      marginLeft: theme.spacing(1),
                    }}
                  >
                    Pump Off
                  </Typography>
                )}
            </div>
          )}
        <List>
          {statusRanges.map((th, i) => (
            <ThresholdRange
              key={`${this.props.type}_${th.status}_${i}`}
              site={this.props.site}
              isEdited={editedStatusRangeIndex === i}
              threshold={th}
              editedStatusRange={this.state.editedStatusRange}
              index={i}
              onPumpControlChange={this.handlePumpControlChange}
              handleEditButtonClick={this.handleEditButtonClicked}
              handleRemoveButtonClick={this.handleRemoveButtonClick}
              handleCancelButtonClick={this.handleCancelButtonClick}
              handleSaveThreshold={this.saveThreshold}
              formRef={this.form}
              isSaveEnabled={this.state.saveEnabled}
              telemetryType={this.props.type}
              handleThresholdChange={this.handleThresholdChange}
              checkIntersectingRule={this.checkIntersectingRule}
              isAdmin={this.props.isAdmin}
              tenantId={this.props.tenantId}
              launchDarklyClientsideId={this.props.launchDarklyClientsideId}
              handleAddConfigPumps={this.handleAddConfigPumps}
            />
          ))}
        </List>
      </div>
    )
  }

  handlePumpControlChange = (isChecked: boolean) => {
    this.setState((prevState) => ({
      threshold: {
        ...prevState.threshold,
        allowPumpControl: isChecked,
      },
    }))
  }

  handleAddConfigPumps = (indexCheck: number, selectedItems: string[]) => {
    this.setState((prevState) => ({
      saveEnabled: true,
      selectedPumps: {
        index: indexCheck,
        items: selectedItems,
      },
    }))
  }

  private readonly handleEditButtonClicked = (
    editedStatusRangeIndex,
    statusRange
  ) => {
    this.setState({
      editedStatusRangeIndex,
      editedStatusRange: StatusRange.fromJS(statusRange),
      saveEnabled: false,
    })
  }

  private handleCancelButtonClick() {
    this.setState({
      editedStatusRange: undefined,
      editedStatusRangeIndex: undefined,
    })
  }

  private readonly saveThreshold = () => {
    const {
      editedStatusRangeIndex: editedThresholdIndex,
      editedStatusRange: editedThreshold,
      saveEnabled,
      selectedPumps,
    } = this.state

    if (!saveEnabled) {
      this.setState({
        editedStatusRange: undefined,
        editedStatusRangeIndex: undefined,
        selectedPumps: null,
      })
      return
    }

    if (editedThreshold && selectedPumps?.items) {
      editedThreshold.pumpsAssetsIdControlled = selectedPumps.items
    }

    const thresholds = this.state.statusRanges
      .map((th, i) => (i !== editedThresholdIndex ? th : editedThreshold!))
      .sort((th1, th2) => th1.minThreshold - th2.minThreshold)

    const tickMarks = this.getTicks(thresholds)

    this.setState(
      {
        statusRanges: thresholds,
        tickMarks,
        editedStatusRange: undefined,
        editedStatusRangeIndex: undefined,
        selectedPumps: null,
      },
      () => this.componentStateUpdated()
    )
  }

  private *getRangeErrors(statusRanges: StatusRange[]) {
    let key = 1
    const rangeError = (error) => (
      <Typography
        key={key++}
        style={{
          padding: 10,
          textAlign: 'center',
        }}
        color="error"
      >
        {error}
      </Typography>
    )

    if (statusRanges.length === 0) {
      yield (
        <Typography
          key={0}
          style={{
            padding: 10,
            textAlign: 'center',
          }}
        >
          No thresholds defined.
        </Typography>
      )
    }

    if (!this.hasAtLeastOneGreenIfNotEmpty()) {
      yield rangeError('Define a green range.')
    }

    if (!this.hasNoGapsBetweenThresholds()) {
      yield rangeError('No gaps allowed.')
    }

    if (!this.hasNoIntersectedThresholds()) {
      yield rangeError('No intersections allowed.')
    }
  }

  private renderThresholdRanges() {
    const { statusRanges: thresholds } = this.state
    const rangeErrors = [...this.getRangeErrors(thresholds)]

    if (rangeErrors.length) {
      return (
        <Grid item={true} xs={12}>
          {rangeErrors}
        </Grid>
      )
    }

    const { tickMarks, editedStatusRange: editedThreshold } = this.state
    const processedTicks = this.getProcessedTicks(this.props.type, tickMarks)
    const min = thresholds[0].minThreshold
    const max = processedTicks[processedTicks.length - 1]
    const minMaxMargin = 5
    const trackStyle: React.CSSProperties[] = thresholds.map((t) => ({
      backgroundColor: getStatusLensColor(t.status),
    }))
    const marksSet = new Set(processedTicks)
    const marksInitial = [...marksSet.values()]
    const marks = marksInitial.reduce((marks, m, mIndex) => {
      const width = this.isNearAnotherMark(marksInitial, mIndex) ? '20px' : null
      const markPossition = this.getMarkPossition(marksInitial, mIndex)
      return {
        ...marks,
        [m]: {
          style: {
            width: width,
            marginTop: markPossition.marginTop,
            paddingRight: markPossition.paddingRight,
            paddingLeft: markPossition.paddingLeft,
          } as React.CSSProperties,
          label: this.getRangeLabel(this.props.type, m),
        },
      }
    }, {})

    return (
      <Range
        value={processedTicks}
        allowCross={false}
        min={min - minMaxMargin}
        max={max + minMaxMargin}
        trackStyle={trackStyle}
        marks={marks}
        railStyle={{
          backgroundColor: getStatusLensColor(TelemetryStatus.Red, 0.5),
        }}
        onChange={this.onTickMarksChange}
        style={{
          padding: theme.spacing(2, 0, 4, 0),
        }}
        disabled={!!editedThreshold}
      />
    )
  }

  private readonly getProcessedTicks = (
    type: TelemetryType,
    ranges: number[]
  ) => {
    return type === TelemetryType.HeaterTemp
      ? ranges.map((item) => celsiusToFahrenheit(item))
      : ranges
  }

  private readonly isNearAnotherMark = (
    marksInitial: number[],
    currentIndex: number
  ): boolean => {
    const marksCount = marksInitial.length - 1
    const [prev, curr, next] = [
      ...marksInitial.slice(Math.max(0, currentIndex - 1), currentIndex + 2),
    ]
    return (
      (curr - prev) / marksCount <= 1.5 || (next - curr) / marksCount <= 1.5
    )
  }

  private readonly getMarkPossition = (
    marksInitial: number[],
    currentIndex: number
  ): ThresholdsMarkPossition => {
    const marksCount = marksInitial.length - 1
    const [prev, curr, next] = [
      ...marksInitial.slice(Math.max(0, currentIndex - 1), currentIndex + 2),
    ]
    const maxValue = marksInitial[marksCount]
    const isFirst = currentIndex === 0

    if (isFirst) {
      return this.getFirstMakrPossition()
    }

    const isLast = currentIndex === marksCount

    if (isLast) {
      return this.getLastMakrPossition()
    }

    const isOverlappingWithPrevious = ((curr - prev) / maxValue) * 100 < 10
    const isOverlappingWithNext = ((next - curr) / maxValue) * 100 < 10
    const isInMiddleOfOverlapped =
      isOverlappingWithNext && isOverlappingWithPrevious

    switch (true) {
      case isInMiddleOfOverlapped:
        return this.getMarkInMiddleOfOverlappingPossition()
      case isOverlappingWithPrevious:
        return this.getMarkOverlappingWithPreviousPossition()
      case isOverlappingWithNext:
        return this.getMarkOverlappingWithNextPossition()
      default:
        return this.getMakrWithDefaultPossition()
    }
  }

  private readonly getFirstMakrPossition = () => {
    return {
      paddingLeft: null,
      paddingRight: theme.spacing(4),
      marginTop: theme.spacing(1),
    } as ThresholdsMarkPossition
  }

  private readonly getLastMakrPossition = () => {
    return {
      paddingLeft: theme.spacing(4),
      paddingRight: null,
      marginTop: theme.spacing(1),
    } as ThresholdsMarkPossition
  }

  private readonly getMarkInMiddleOfOverlappingPossition = () => {
    return {
      paddingLeft: null,
      paddingRight: null,
      marginTop: theme.spacing(3),
    } as ThresholdsMarkPossition
  }

  private readonly getMarkOverlappingWithPreviousPossition = () => {
    return {
      paddingLeft: theme.spacing(3),
      paddingRight: null,
      marginTop: theme.spacing(1),
    } as ThresholdsMarkPossition
  }

  private readonly getMarkOverlappingWithNextPossition = () => {
    return {
      paddingLeft: null,
      paddingRight: theme.spacing(3),
      marginTop: theme.spacing(1),
    } as ThresholdsMarkPossition
  }

  private readonly getMakrWithDefaultPossition = () => {
    return {
      paddingLeft: null,
      paddingRight: null,
      marginTop: theme.spacing(1),
    } as ThresholdsMarkPossition
  }

  private readonly getRangeLabel = (type: TelemetryType, value: number) => {
    return type === TelemetryType.TankLevel ||
      type === TelemetryType.BalancedTank
      ? formatActualTankLevelFeet(value)
      : value
  }

  private readonly onTickMarksChange = (tickMarks: number[]) => {
    const tickMarksSet = new Set(tickMarks)

    if (tickMarksSet.size !== tickMarks.length) {
      return
    }

    const statusRanges = this.getStatusRanges(
      tickMarks,
      this.state.statusRanges
    )

    this.setState({ statusRanges, tickMarks }, () =>
      this.componentStateUpdated()
    )
  }

  private getStatusRanges(
    tickMarks: number[],
    prevStatusRanges: StatusRange[]
  ) {
    const ranges = tickMarks
      .reduce((rs, v, i) => {
        if (i < tickMarks.length - 1) {
          const range = [v, tickMarks[i + 1]]
          rs.push(range)
        }

        return rs
      }, [] as number[][])
      .sort((a, b) => a[0] - b[0])

    return ranges.map(([minThreshold, maxThreshold], i) =>
      StatusRange.fromJS({
        ...prevStatusRanges[i],
        minThreshold,
        maxThreshold,
      })
    )
  }

  private get isAddButtonDisabled() {
    const isFlare = this.props.type === TelemetryType.FlareStatus
    const isPump = this.props.type === TelemetryType.PumpControl

    let maxTh = !isFlare
      ? maximumThresholdsPerStatusAllowed
      : maximumFlareStatusThresholdsPerStatusAllowed

    maxTh = !isPump ? maxTh : maximumPumpControlStatusThresholdsPerStatusAllowed

    const { statusRanges: thresholds } = this.state
    const statusThresholdsCounts = supportedTelemetryStatuses.map((status) => {
      if (isFlare && status === TelemetryStatus[TelemetryStatus.Yellow])
        return 1
      return thresholds.reduce(
        (count, th) =>
          TelemetryStatus[th.status] === status ? count + 1 : count,
        0
      )
    })

    return statusThresholdsCounts.every((count) => count === maxTh)
  }

  private readonly handleRemoveButtonClick = (index: number) => {
    const thresholds = this.state.statusRanges.filter((_, i) => i !== index)
    const tickMarks = this.getTicks(thresholds)

    this.setState({ statusRanges: thresholds, tickMarks }, () =>
      this.componentStateUpdated()
    )
  }

  private handleThresholdChange(
    stringValue: string,
    index: number,
    property: keyof Pick<
      StatusRange,
      'minThreshold' | 'maxThreshold' | 'isHighPriority' | 'allowPumpControl'
    >
  ) {
    const highPriorityChanged = property === 'isHighPriority'
    const allowPumpControlChanged = property === 'allowPumpControl'

    let value: any = parseFloat(stringValue)

    if (highPriorityChanged || allowPumpControlChanged) {
      value = stringValue === 'true'
    }

    const newValue =
      highPriorityChanged || allowPumpControlChanged
        ? value
        : isNaN(value)
        ? undefined
        : value

    const editedThreshold = StatusRange.fromJS({
      ...this.state.editedStatusRange,
      [property]: newValue,
    })

    this.setState({ editedStatusRange: editedThreshold }, async () => {
      const saveEnabled =
        (await this.isFormValid()) &&
        JSON.stringify(this.state.editedStatusRange).localeCompare(
          JSON.stringify(this.state.statusRanges[index])
        ) !== 0

      this.setState({ saveEnabled })
    })
  }

  private handleAddButtonClick(status: TelemetryStatus) {
    if (this.props.type === TelemetryType.FlareStatus) {
      this.createFlareThreshold(status)
    } else {
      if (this.props.type === TelemetryType.PumpControl) {
        this.createPumpThreshold(status)
      } else {
        const statusRangesCount = this.state.statusRanges.length
        const minThreshold =
          statusRangesCount > 0
            ? this.state.statusRanges[statusRangesCount - 1].maxThreshold
            : 0
        const maxThreshold = minThreshold !== undefined ? minThreshold + 10 : 10
        const isHighPriority = false
        const statusRangeToAdd = StatusRange.fromJS({
          status,
          minThreshold,
          maxThreshold,
          isHighPriority,
        })
        const statusRanges = this.state.statusRanges.concat(statusRangeToAdd)
        const tickMarks = this.getTicks(statusRanges)

        this.setState(
          {
            statusRanges: statusRanges,
            tickMarks,
            isAddMenuOpened: false,
          },
          () => {
            this.handleEditButtonClicked(
              statusRanges.length - 1,
              statusRangeToAdd
            )
            this.componentStateUpdated()
          }
        )
      }
    }
  }

  private createPumpThreshold(status: TelemetryStatus) {
    const statusRangeToAdd = StatusRange.fromJS({
      status,
      minThreshold: status === TelemetryStatus.Red ? 0 : 1,
      maxThreshold: status === TelemetryStatus.Red ? 1 : 2,
      isHighPriority: false,
      isPumpOff: false,
    })

    const statusRanges = this.state.statusRanges.concat(statusRangeToAdd)
    const tickMarks = this.getTicks(statusRanges)

    this.setState(
      {
        statusRanges: statusRanges,
        tickMarks,
        isAddMenuOpened: false,
      },
      () => {
        this.componentStateUpdated()
      }
    )
  }

  private createFlareThreshold(status: TelemetryStatus) {
    const statusRangeToAdd = StatusRange.fromJS({
      status,
      minThreshold: status === TelemetryStatus.Red ? 0 : 1,
      maxThreshold: status === TelemetryStatus.Red ? 1 : 2,
      isHighPriority: false,
    })

    const statusRanges = this.state.statusRanges.concat(statusRangeToAdd)
    const tickMarks = this.getTicks(statusRanges)

    this.setState(
      {
        statusRanges: statusRanges,
        tickMarks,
        isAddMenuOpened: false,
      },
      () => {
        this.componentStateUpdated()
      }
    )
  }

  private hasNoIntersectedThresholds() {
    const { statusRanges: thresholds } = this.state

    for (let i = 0; i < thresholds.length; i = i + 1) {
      for (let j = 0; j < thresholds.length; j = j + 1) {
        if (i === j) {
          continue
        }

        const min = thresholds[i].minThreshold
        const max = thresholds[i].maxThreshold

        if (min >= max) {
          return false
        }

        const otherMax = thresholds[j].maxThreshold
        const otherMin = thresholds[j].minThreshold

        if (max <= otherMax && max > otherMin) {
          return false
        }

        if (min < otherMax && min > otherMin) {
          return false
        }

        if (max >= otherMax && min <= otherMin) {
          return false
        }
      }
    }

    return true
  }

  private hasNoGapsBetweenThresholds(editedStatusRange?: StatusRange) {
    const {
      statusRanges: thresholds,
      editedStatusRangeIndex: editedThresholdIndex,
    } = this.state
    const allThresholds = editedStatusRange
      ? thresholds.map((th, i) =>
          editedThresholdIndex !== i ? th : editedStatusRange
        )
      : thresholds
    const thresholdsToCheck = allThresholds
      .sort((th1, th2) => th1.minThreshold - th2.minThreshold)
      .slice(0, allThresholds.length - 1)

    return (
      !thresholdsToCheck.length ||
      thresholdsToCheck.every(
        (th, i) => th.maxThreshold === allThresholds[i + 1].minThreshold
      )
    )
  }

  private hasAtLeastOneGreenIfNotEmpty() {
    const { statusRanges: thresholds } = this.state

    return (
      thresholds.length === 0 ||
      thresholds.findIndex((th) => th.status === TelemetryStatus.Green) >= 0
    )
  }
}
